import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

// MUI Imports
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { formattedDateSoulPlan } from "utils/helpers";
import { createUser } from "services/user.service";
import { editUser } from "services/user.service";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { yupResolver as FormResolver } from "@hookform/resolvers/yup";
import { userFormSchema } from "utils";

const options = [
  { label: "Matrix", value: "Matrix" },
  { label: "Soul Plan", value: "Soul Plan" },
];

const UserForm = ({
  data,
  open,
  handleClose,
  userData,
  setUserData,
  setUpdatedData,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: data,
    resolver: FormResolver(userFormSchema()),
  });

  useEffect(() => {
    if (data?.id) {
      reset({
        ...data,
        birthday: dayjs(data.birthday),
      });
    } else {
      resetForm();
      setUpdatedData(null);
    }
  }, [data, reset, setUpdatedData]);

  const resetForm = () => {
    reset({ name: "", birthday: "", email: "", level: [], phone_number: "" });
  };
  const handleCreate = async (data) => {
    data.birthday = formattedDateSoulPlan(data.birthday);
    // data.password = "123456";
    data.active = !!data.active;
    try {
      const response = await createUser(data);
      if (response.status === 200) {
        setUserData((prev) => [response?.data?.data, ...prev]);
      }
      toast.success("Tạo user thành công");
      handleClose();
      resetForm();
    } catch (error) {
      const msg = error?.response?.data?.data;
      for (let prop in msg) {
        toast.error(prop + ": " + msg[prop]);
      }
      //   toast.error("Đã có lỗi xảy ra");
    }
  };

  const handleUpdate = async (data) => {
    data.active = !!data.active;
    try {
      const response = await editUser({ data, userId: data.id });
      if (response.status === 200) {
        const index = userData.findIndex((item) => item.id === data?.id);
        if (index !== -1) {
          const updatedUserData = [...userData]; // Create a copy of the userData array
          updatedUserData[index] = response.data.data; // Update the specific user

          setUserData(updatedUserData); // Update the state with the new array
        }
      }
      toast.success("Update user thành công");
      handleClose();
      setUpdatedData(null);
      resetForm();
    } catch (error) {
      const msg = error?.response?.data?.data;
      for (let prop in msg) {
        toast.error(prop + ": " + msg[prop]);
      }
    }
  };
  const onSubmit = async (data) => {
    if (data?.id) {
      await handleUpdate(data);
    } else {
      await handleCreate(data);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <DialogContent id="edit-dialog-title">
          <form onSubmit={handleSubmit(onSubmit)} className="p-0">
            <Typography variant="h4" className="text-center">
              {data?.id ? "Cập nhật người dùng" : "Tạo người dùng"}
            </Typography>
            <Typography
              variant="body1"
              className="text-center text-red-600"
            ></Typography>
            <Box className="mt-5 w-full">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="my-2">Họ và tên *</Typography>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        placeholder="Nguyễn Văn A"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <i className="ri-user-3-line" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errors.name && (
                    <span className="text-[14px] text-red-600">Nhập tên</span>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography className="my-2">Email</Typography>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        placeholder="nguyen@gmail.com"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <i className="ri-user-3-line" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errors.email && (
                    <span className="text-[14px] text-red-600">
                      Nhập email *
                    </span>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <Typography className="my-2">Ngày sinh</Typography>
                  <Controller
                    name="birthday"
                    control={control}
                    render={({ field }) => {
                      const dateValue = field.value
                        ? new Date(field.value)
                        : new Date(); // Default to current date if field.value is invalid

                      return (
                        <DatePicker
                          {...field}
                          selected={
                            !isNaN(dateValue.getTime()) ? dateValue : null // Ensure valid date
                          }
                          onChange={(date) => field.onChange(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Chọn ngày sinh"
                          className="h-10 w-2/5 rounded-md border border-gray-300 px-3"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography className="my-2">Số điện thoại *</Typography>
                  <Controller
                    name="phone_number"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        placeholder="0939899968"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <i className="ri-user-3-line" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errors.phone_number && (
                    <span className="text-[14px] text-red-600">
                      Nhập số điện thoại
                    </span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography className="my-2">Cấp độ *</Typography>
                  <Controller
                    name="level"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="select-label"
                        multiple
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.level && (
                    <span className="text-[14px] text-red-600">
                      Chọn cấp độ
                    </span>
                  )}
                </Grid>
                {/* {data?.id && ( */}
                <Grid item xs={12}>
                  <Typography className="my-2">
                    {data?.id ? "Cấp lại mật Khật" : "Mật khẩu"}
                  </Typography>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        placeholder="*********"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <i className="ri-user-3-line" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                {/* )} */}
                <Grid item xs={6}>
                  <Typography variant="body1">Giới tính:</Typography>
                  <Controller
                    name="gender"
                    control={control}
                    defaultValue="male"
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Nam"
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Nữ"
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label="Khác"
                        />
                      </RadioGroup>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Trạng thái</Typography>
                  <Controller
                    name="active"
                    control={control}
                    defaultValue={true}
                    render={({ field }) => {
                      const { value, onChange, onBlur } = field;

                      return (
                        <RadioGroup
                          value={value === true ? "true" : "false"} // Convert boolean to string
                          onChange={(event) => {
                            // Convert string to boolean
                            const selectedValue = event.target.value === "true";
                            onChange(selectedValue);
                          }}
                          onBlur={onBlur}
                        >
                          <FormControlLabel
                            value="true" // Use string representation
                            control={<Radio />}
                            label="Active"
                          />
                          <FormControlLabel
                            value="false" // Use string representation
                            control={<Radio />}
                            label="Inactive"
                          />
                        </RadioGroup>
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  variant="outlined"
                  className="border-solid border-primaryPurple text-primaryPurple"
                  onClick={handleClose}
                >
                  Hủy bỏ
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="bg-primaryPurple"
                >
                  {data?.id ? "Cập nhật" : "Tạo mới"}
                </Button>
              </DialogActions>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserForm;
