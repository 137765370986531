import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";

// Icon Imports
import SoulPlan from "views/admin/soul-plan";
import UserTable from "views/admin/user";
import Images from "./Images";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export const MenuItems = [
  {
    name: "Thông tin user",
    layout: "/admin",
    path: "default",
    icon: (
      <ContactPageIcon className="h-6 w-6 text-primaryPurple" alt="six star" />
    ),
    component: <MainDashboard />,
  },
  {
    name: "Soul Plan",
    layout: "/admin",
    icon: <img className="h-6 w-6" src={Images.matrix} alt="six star" />,
    path: "soul-plan",
    component: <SoulPlan />,
  },
  {
    name: "User",
    layout: "/admin",
    icon: (
      <AccountCircleIcon
        className="h-6 w-6 text-primaryPurple"
        alt="six star"
      />
    ),
    path: "user",
    component: <UserTable />,
  },

  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
];

export const MenuItemsUser = [
  {
    name: "Thông tin user",
    layout: "/admin",
    path: "default",
    icon: (
      <ContactPageIcon className="h-6 w-6 text-primaryPurple" alt="six star" />
    ),
    component: <MainDashboard />,
  },
  {
    name: "Soul Plan",
    layout: "/admin",
    icon: <img className="h-6 w-6" src={Images.matrix} alt="six star" />,
    path: "soul-plan",
    component: <SoulPlan />,
  },
];

export default MenuItems;
