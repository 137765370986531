import React from "react";
import { useForm } from "react-hook-form";
import "./index.css";
import Images from "constant/Images";
import { loginUser } from "services/user.service";
import { setLocalStorage } from "utils/local-storage";
import { USER_INFO } from "constant/AuthConstant";
import { saveToCookieStorage } from "utils/cookie";
import { AUTHENTICATION } from "constant/AuthConstant";
import {  useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Email, Lock,  } from "@mui/icons-material";

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const response = await loginUser(data);
      toast.success("Đăng nhập thành công");
      setLocalStorage(USER_INFO, response.data);
      saveToCookieStorage(AUTHENTICATION, response.data.access_token);
      navigate("/");
    } catch (err) {
      toast.error("Đã có lỗi xảy ra");
    }
  };

  return (
    <div className="container__signin">
      <div className="forms-container">
        <div className="signin-signup" id="login">
          {/* Use handleSubmit to handle form submission */}
          <form
            id="login"
            className="sign-in-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h2 className="title text-primaryPurple">Quản Lý Hệ Thống</h2>

            {/* Username input */}
            <div className="input-field flex items-center gap-4">
              <Email/>
              <input
                type="text"
                style={{ backgroundColor: "transparent" }}
                placeholder="Email"
                id="email"
                autoComplete="off"
                className="w-full h-ful"
                {...register("email", { required: true })} // Registering input with validation
              />
              {/* Display error message if email is not entered */}
              {errors.email && <span className="error">email is required</span>}
            </div>

            {/* Password input */}
            <div className="input-field flex items-center gap-4">
              <Lock/>
              <input
                type="password"
                style={{ backgroundColor: "transparent" }}
                placeholder="Password"
                id="password"
                autoComplete="off"
                className="w-full"
                {...register("password", { required: true })} // Registering input with validation
              />
              {/* Display error message if password is not entered */}
              {errors.password && (
                <span className="error">Password is required</span>
              )}
            </div>

            {/* Submit button */}
            <input
              type="submit"
              value="ĐĂNG NHẬP"
              className="btn solid bg-primaryPurple"
              id="submit"
            />
          </form>
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <img
              src={Images.LogoSoul}
              className="logo object-contain"
              style={{ width: "80%", height: "80%" }}
              alt=""
            />
            <p className="text-primaryPurple">
              Hạnh phúc của bạn là sứ mệnh của chúng tôi
            </p>
          </div>
          <img src={Images.log} className="image" alt="" />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>OYL GUIDBOOK</h3>
            <p>OWN YOUR LIFE</p>
            <button className="btn transparent" id="sign-in-btn">
              ĐĂNG NHẬP
            </button>
          </div>
          <img src="/images/log.svg" className="image" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Login;
