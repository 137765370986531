// export const HOST = 'https://be.cicinhakhanh.com/api'
export const HOST = "https://be.cicinhakhanh.com/api";
export const HOSTWEB = "https://be.cicinhakhanh.com";
// import.meta.VITE_CLIENT_MODE === 'development'
//   ? import.meta.VITE_CLIENT_HOST_DEV
//   : import.meta.VITE_CLIENT_HOST_PRODUCTION

export const AUTH = "/auth";

export const REGISTER = "/register";
export const LOGIN = "/login";
export const INFO_USER = "/me";
export const USER = "/users";

export const MATRIX = "/soul_plans";

export const VIEW = "/view";
export const EXPORT = "/export";

export const UPLOAD_URL = "/upload";
export const FILE = "/file";
export const PROXY_IMAGE = "/proxy-image";

export const AVATAR_FOLDER = "avatar";
