"use client";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

// MUI Imports
import Grid from "@mui/material/Grid";
import { Box, Button, TextField, Pagination, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Components Imports
// import CardStudent from "./components/CardStudent";
import { Cached } from "@mui/icons-material";
import { getUser } from "services/user.service";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { deleteUser } from "services/user.service";
import { toast } from "react-toastify";
import UserForm from "./components/UserForm";
import DeleteForm from "./components/DeleteForm";

const UserTable = () => {
  const [open, setOpen] = useState(false);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const [userData, setUserData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [updatedData, setUpdatedData] = useState(null);
  const [deletedId, setDeletedId] = useState(null);

  const [pagination, setPagination] = useState({
    per_page: 10,
    page: 1,
    total_pages: 1,
  });

  const { handleSubmit, control, reset } = useForm();

  // modal create + updated
  const handlOpen = (data) => {
    setOpen(true);
    if (data?.id) {
      setUpdatedData(data);
    } else {
      setUpdatedData(null);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setUpdatedData(null);
  };

  const handleToggleDeleteModal = (id) => {
    setOpenDialogConfirmDelete(!openDialogConfirmDelete);
    if (id) {
      setDeletedId(id);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const response = await deleteUser(deletedId);
      if (response.status === 200) {
        const updatedUserData = userData.filter(
          (item) => item.id !== deletedId
        );
        setUserData(updatedUserData); // Update the state with the new array
      }
      toast.success("Xóa user thành công");
      handleToggleDeleteModal();
    } catch (error) {
      toast.error("Đã có lỗi xảy ra");
    }
  };

  const onSearch = (data) => {
    const { name, email } = data;

    getUser({ ...pagination, name, email }).then((data) => {
      setUserData(data?.data?.data);
      setTotalItems(data?.data?.total_items);
      setPagination((prev) => ({
        ...prev,
        total_pages: data?.data?.total_pages,
      }));
    });
  };

  useEffect(() => {
    callApiGetData();
  }, [pagination.page]);

  const handlePageChange = (event, value) => {
    setPagination((prev) => ({ ...prev, page: value }));
  };

  const callApiGetData = () => {
    try {
      getUser(pagination).then((data) => {
        setUserData(data?.data?.data);
        setTotalItems(data?.data?.total_items);
        setPagination((prev) => ({
          ...prev,
          total_pages: data?.data?.total_pages,
        }));
      });
    } catch (error) {
      toast.error("Đã có lỗi xảy ra!");
    }
  };
  const handleClear = () => {
    callApiGetData();
    reset();
  };

  return (
    <>
      {/* Dialog create report */}
      <UserForm
        open={open}
        handleClose={handleClose}
        data={updatedData}
        userData={userData}
        setUserData={setUserData}
        setUpdatedData={setUpdatedData}
      />
      <DeleteForm
        open={openDialogConfirmDelete}
        handleClose={handleToggleDeleteModal}
        handleDeleteUser={handleDeleteUser}
      />
      <Box className="mt-4 flex justify-between gap-2">
        <Box className="w-2/5">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Tìm theo họ và tên"
                variant="outlined"
                size="small"
                className="w-2/5"
              />
            )}
          />
        </Box>
        <Box className="w-2/5">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Tìm theo Email"
                variant="outlined"
                size="small"
                // className="w-2/5"
              />
            )}
          />
        </Box>
        {/* <Controller
          name="birthday"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Tìm theo ngày Sinh"
              variant="outlined"
              size="small"
              className="w-2/5"
            />
          )}
        /> */}
        <Button
          variant="outlined"
          // sx={{ color: "#a881fe" }}
          onClick={handleSubmit(onSearch)}
          className="w-1/5 border-primary normal-case text-primary"
        >
          Tìm kiếm
        </Button>
        <Button
          variant="outlined"
          // sx={{ color: "#a881fe" }}
          onClick={handleClear}
          className="w-1/5 border-primary normal-case text-primary"
        >
          <Cached /> Tải lại
        </Button>
      </Box>
      <Box className="my-6">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Box className="flex items-center justify-between">
              <Button
                className=" normal-case text-primaryPurple"
                sx={{ border: "1px solid #a881fe" }}
                variant="outlined"
                onClick={handlOpen}
              >
                Tạo người dùng
              </Button>
              <Typography className="font-bold">
                {totalItems} Người dùng
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="mt-5">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="font-bold">
                  Tên
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Email
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Số điện thoại
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Cấp độ
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Giới tính
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Quyền người dùng
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Số báo cáo tuần
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Số báo cáo tháng
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Tổng báo cáo
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Trạng thái
                </TableCell>
                <TableCell align="center" className="font-bold">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData &&
                userData.map((user, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {user?.name}
                    </TableCell>
                    <TableCell align="left">{user?.email}</TableCell>
                    <TableCell align="left">{user?.phone_number}</TableCell>
                    <TableCell align="left">{user?.level}</TableCell>
                    <TableCell align="left">{user?.gender}</TableCell>
                    <TableCell align="left">{user?.role}</TableCell>
                    <TableCell align="left">{user?.weekly_matrix}</TableCell>
                    <TableCell align="left">{user?.monthly_matrix}</TableCell>
                    <TableCell align="left">{user?.total_matrix}</TableCell>
                    <TableCell align="left">
                      {user?.active ? (
                        <Typography className="rounded-full bg-green-600 px-2 py-1 text-xs text-white">
                          Active
                        </Typography>
                      ) : (
                        <Typography className="rounded-full bg-orange-600 px-2 py-1 text-xs text-white">
                          Inactive
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <Box>
                        <Button onClick={() => handlOpen(user)}>
                          <EditNoteIcon className="text-blue-700" />
                        </Button>
                        <Button
                          onClick={() => handleToggleDeleteModal(user?.id)}
                        >
                          <DeleteOutlineIcon className="text-red-700" />
                        </Button>
                        {/* Dialog comfirm delete */}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Pagination */}
      <Box className="mt-16">
        <Grid container spacing={6} justifyContent="center" className="mt-8">
          <Pagination
            count={pagination.total_pages}
            page={pagination.page}
            onChange={handlePageChange}
          />
        </Grid>
      </Box>
    </>
  );
};

export default UserTable;
