import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import BlankLayout from "layouts/blank";
import PrivateRoute from "components/PrivateRoute";
import { getInfoUser } from "services/user.service";
import { setLocalStorage } from "utils/local-storage";
import { USER_INFO } from "constant/AuthConstant";
import { removeFromLocalStorage } from "utils/local-storage";
import { removeFromCookieStorage } from "utils/cookie";
import { AUTHENTICATION } from "constant/AuthConstant";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUser } from "store/userReducer";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        const response = await getInfoUser();
        dispatch(setUser(response.data.user));
        setLocalStorage(USER_INFO, response.data);
      } catch (error) {
        removeFromLocalStorage(USER_INFO);
        removeFromCookieStorage(AUTHENTICATION);
        navigate("/auth/sign-in");
        toast.error("Đã có lỗi xảy ra. Vui lòng đăng nhập lại!");
      }
    })();
  }, []);
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
        path="admin/*"
        element={<PrivateRoute element={<AdminLayout />} />}
      />
      <Route
        path="blank/*"
        element={<PrivateRoute element={<BlankLayout />} />}
      />
      <Route path="/" element={<Navigate to="/admin" replace />} />
    </Routes>
  );
};

export default App;
