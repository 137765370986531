import Images from "constant/Images";
import React from "react";

const ImageIcon = ({ data, position, width = "28px" }) => {
  return (
    <>
      <img
        src={Images[`icon${data?.split("-")[position]}`]}
        className="mx-auto mr-[2px]"
        style={{ width: width }}
        alt="tick blue"
      />
    </>
  );
};

export default ImageIcon;
