import axios from "axios";

import { AUTH, HOST, INFO_USER, LOGIN, REGISTER } from "constant/Api";
import { fetchClient } from "api/app.service";
import { USER } from "constant/Api";

export const loginUser = async (data) => {
  // Use fetchClient() when call api have token
  return axios
    .post(`${HOST}${AUTH}${LOGIN}`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};


export const registerUser = async (data) => {
  return axios
    .post(`${HOST}${REGISTER}`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const createUser = async (data) => {
  // Use fetchClient() when call api have token
  return fetchClient(true)
    .post(`${HOST}${USER}`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const editUser = async ({ data, userId }) => {
  // Use fetchClient() when call api have token
  return fetchClient(true)
    .put(`${HOST}${USER}/${userId}`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteUser = async (userId) => {
  // Use fetchClient() when call api have token
  return fetchClient(true)
    .delete(`${HOST}${USER}/${userId}`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUser = async (param) => {
  return fetchClient(true)
    .get(`${USER}`, { params: param })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const getInfoUser = async (params) => {
  // Use fetchClient() when call api have token
  return fetchClient(true)
    .get(`${HOST}${AUTH}${INFO_USER}`, { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};
