import React from "react";
import { Box } from "@mui/material";
import ImageIcon from "views/admin/soul-plan/components/ImageIcon";

const SoulPlanIcons = ({ soulPlan }) => {
  const targetSpirit = soulPlan?.split("-");

  if (!targetSpirit || targetSpirit.length === 0) {
    return null;
  }

  return (
    <>
      {targetSpirit[0] === targetSpirit[1] ? (
        <Box className="flex justify-center">
          {targetSpirit[0] && <ImageIcon data={soulPlan} position={0} />}
        </Box>
      ) : (
        <Box className="flex">
          {targetSpirit[0] && <ImageIcon data={soulPlan} position={0} />}
          {targetSpirit[1] && <ImageIcon data={soulPlan} position={1} />}
        </Box>
      )}
    </>
  );
};

export default SoulPlanIcons;
