// MUI Imports
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";

// Components Imports
import Images from "constant/Images";
import TrainingLevels from "./components/TrainingLevels";
import CardToolsExport from "./components/CardToolsExport";

const dataCardExportReport = [
  {
    title: "Soul Plan Personal",
    thumbnail: Images.iconSixPointedStar,
  },
  {
    title: "Soul Plan Group Report",
    thumbnail: Images.group,
  },
  {
    title: "Matrix Of Destiny",
    thumbnail: Images.matrix,
  },
];

const Dashboard = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={12}>
        <TrainingLevels />
      </Grid>

      <Grid item xs={12} lg={12}>
        <Typography variant="h5" className="text-primary">
          Công cụ xuất Báo cáo
        </Typography>
      </Grid>

      {dataCardExportReport.map((item, index) => (
        <Grid key={index} item xs={6} md={4} lg={4}>
          <CardToolsExport thumbnail={item?.thumbnail} title={item?.title} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Dashboard;
