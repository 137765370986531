// MUI Imports
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const CardToolsExport = (props) => {
  const { thumbnail, title } = props;

  return (
    <Card className="p-0">
      <CardContent className="flex flex-col items-center">
        <img src={thumbnail} alt="trophy" className="h-[60px] text-white" />
        <div>
          <Typography variant="h5" className="text-[16px]">{title}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default CardToolsExport;
