import { AUTHENTICATION } from "constant/AuthConstant";
import React from "react";
import { Navigate } from "react-router-dom";
import { getItemFromCookieStorage } from "utils/cookie";

const PrivateRoute = ({ element }) => {
  const isAuthenticated = !!getItemFromCookieStorage(AUTHENTICATION);

  return isAuthenticated ? element : <Navigate to="/auth/sign-in" />;
};

export default PrivateRoute;
