import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import dayjs from "dayjs";
import { removeFromLocalStorage } from "utils/local-storage";
import { setLocalStorage } from "utils/local-storage";
import { getFromLocalStorage } from "utils/local-storage";
import { AUTHENTICATION } from "constant/AuthConstant";
import { getItemFromCookieStorage } from "utils/cookie";
import { saveToCookieStorage } from "utils/cookie";
import { removeFromCookieStorage } from "utils/cookie";

// export const getUserListAdmin = createAsyncThunk(
//   "user/userList",
//   async (params) => {
//     try {
//       const response = await getAllUser(params);
//       return response.data;
//     } catch (error) {
//       return false;
//     }
//   }
// );

export const usersAdapter = createEntityAdapter();

const initialState = usersAdapter.getInitialState({
  isAuth: !!getItemFromCookieStorage(AUTHENTICATION),
  user: getFromLocalStorage(AUTHENTICATION) || null,
  userTestScore: null,
  userDocument: [],
  userApplication: null,
  userApplicationProcess: [],
  totalUserApplication: 0,
  userApplicationDetail: null,
  visits: null,
  statistics: null,
  loading: false,
  infoVerifyRequest: null,
  userList: [],
  totalUser: 0,
  userDetail: null,
  userInfo: null,
  countAccess: [],
  managerList: [],
  tabApplicationData: [],
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      // const { user, access_token } = action.payload;
      // const data = {
      //   ...user,
      //   birthday: dayjs(user.birthday),
      //   passport_expiry: dayjs(user.passport_expiry),
      // };
      state.user = action.payload;
      // state.isAuth = true;
      // setLocalStorage(AUTHENTICATION, data);
      // saveToCookieStorage(AUTHENTICATION, access_token);
    },
    signOut: (state) => {
      state.user = null;
      state.isAuth = false;
      removeFromLocalStorage(AUTHENTICATION);
      removeFromCookieStorage(AUTHENTICATION);
    },

    updateUser: (state, action) => {
      const { user } = action.payload;
      const data = {
        ...user,
        birthday: dayjs(action.payload.birthday),
        passport_expiry: dayjs(action.payload.passport_expiry),
      };
      state.user = data;
      setLocalStorage(AUTHENTICATION, data);
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getUserListAdmin.fulfilled, (state, action) => {
    //   state.userList = action.payload.data;
    //   state.totalUser = action.payload.total_items;
    // });
  },
});
export const {
  setUser,
  signOut,
  updateUser,
  addMoreUserDocument,
  deleteUser,
  updateUserApplication,
  resetUserForm,
  addUserToList,
  updateUserInfoFromAdmin,
  updateUserFromAdmin,
  setTabApplicationData,
} = userSlice.actions;

const userReducer = userSlice.reducer;
// Selector to check if user is authenticated
export const selectIsAuthenticated = (state) => state.user.isAuth;
export const getUserFromStorage = (state) => state.user.user;
export default userReducer;
