/* eslint-disable no-unused-expressions */
"use client";

import * as React from "react";

// import html2canvas from 'html2canvas'
import jsPDF from "jspdf";

// import html2pdf from 'html2pdf.js'
import html2canvas from "html2canvas-pro";

// MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";

import { Box, Divider } from "@mui/material";
import { formattedDate } from "utils/helpers";
import { Download, Input, OpenInNew } from "@mui/icons-material";
import { USER_INFO } from "constant/AuthConstant";
import { getFromLocalStorage } from "utils/local-storage";
import DialogReport from "./DialogReport";
// import { useNavigate } from "react-router-dom";
import { setLocalStorage } from "utils/local-storage";
import { PDF_DATA } from "constant/AuthConstant";
// import DownloadReport from "./DownloadReport";
// import { toPng } from "html-to-image";
import { modifiedName } from "utils/helpers";
import { HOSTWEB } from "constant/Api";
import { VIEW } from "constant/Api";
import { MATRIX } from "constant/Api";
import { EXPORT } from "constant/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const CardStudent = (data) => {
  const {
    fullName,
    birthday,
    gender,
    creator,
    created_at,
    // awareness,
    // lifeStage,
    // soulPlan,
  } = data.data;

  const [open, setOpen] = React.useState(false);
  const user_info = getFromLocalStorage(USER_INFO);
  // const navigate = useNavigate();
  const viewReport = `${HOSTWEB}${MATRIX}${VIEW}`;
  const exportReport = `${HOSTWEB}${MATRIX}${EXPORT}`;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadPdf = async (id) => {
    const element = document.getElementById(`pdf_content_${id}`);
    if (element) {
      try {
        // Convert the HTML content to canvas
        const canvas = await html2canvas(element, {
          scale: 8, // Adjust the scale as needed
          useCORS: true,
          logging: true,
        });

        const imgData = canvas.toDataURL("image/jpeg");
        const pdf = new jsPDF("p", "mm", "a4");

        // Calculate the dimensions to fit the content on one page
        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (imgHeight <= pdf.internal.pageSize.height) {
          // If the content fits on one page
          pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          // Scale the image to fit on one page
          const scaleFactor = pdf.internal.pageSize.height / imgHeight;
          const scaledWidth = imgWidth * scaleFactor;
          const scaledHeight = imgHeight * scaleFactor;

          pdf.addImage(imgData, "JPEG", 0, 0, scaledWidth, scaledHeight);
        }

        const nameEdited = modifiedName(data?.data?.fullName);

        // Save the PDF
        pdf.save(`${nameEdited}.pdf`);
      } catch (error) {
        toast.error("Đã có lỗi xảy ra!");
      }
    } else {
      toast.error("Đã có lỗi xảy ra!");
    }
  };

  const handleNavigateToPreview = () => {
    setLocalStorage(PDF_DATA, data);
    window.open("/blank/view?nodownload=true", "_blank");
  };
  return (
    <>
      <Card>
        <Box>
          <Box className="flex items-center justify-between bg-purple-100 p-3">
            <Typography className="mb-1 text-[14px] font-semibold capitalize text-primary">
              <strong>
                <OpenInNew onClick={handleClickOpen} /> {fullName}
              </strong>
            </Typography>
            <Typography className="mb-1 text-[14px] font-semibold text-primary">
              <strong> {birthday}</strong>
            </Typography>
          </Box>
          {/* <Divider className="my-6" sx={{ marginY: "10px" }} /> */}
          <Box className="p-2">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  <strong className="text-grey">Họ tên:</strong>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  {fullName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  <strong className="text-grey">Ngày sinh</strong>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  {birthday}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  <strong className="text-grey">Giới tính:</strong>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  {" "}
                  {gender === "male" ? "Nam" : "Nữ"}
                </Typography>
              </Grid>
            </Grid>
            <Divider className="my-3" sx={{ marginY: "10px" }} />
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  <strong className="text-grey">Người tạo:</strong>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  {creator?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  <strong className="text-grey">Ngày tạo:</strong>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="mb-1 text-[14px] font-semibold">
                  {" "}
                  {formattedDate(created_at)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Divider className="" />
        <CardActions className="card-actions-dense">
          <Button
            className="normal-case text-primary"
            onClick={handleClickOpen}
            // href={`${viewReport}/${data.data.id}`}
            target="_blank"
            sx={{ color: "#a881fe" }}
          >
            {/* <PreviewIcon /> */}
            <Input sx={{ mr: "5px", fontSize: "1rem" }} /> Xem báo cáo
          </Button>
          <Button
            onClick={handleNavigateToPreview}
            // href={`${exportReport}/${data.data.id}`}
            className="normal-case text-primary"
          >
            <Download sx={{ mr: "5px", fontSize: "1rem" }} /> Tải về máy
          </Button>
        </CardActions>
      </Card>

      {/* Render the DownloadReport component in a hidden div */}
      {/* <div style={{ display: "none" }}>
        <DownloadReport data={data} user_info={user_info} />
      </div> */}
      <DialogReport
        open={open}
        handleClose={handleClose}
        user_info={user_info}
        data={data}
        handleDownloadPdf={handleDownloadPdf}
      />
    </>
  );
};

export default CardStudent;
