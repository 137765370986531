// MUI Imports
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// Component Imports
import { Avatar, Box, Divider } from "@mui/material";
import Images from "constant/Images";
import { USER_INFO } from "constant/AuthConstant";
import { getFromLocalStorage } from "utils/local-storage";
import { useSelector } from "react-redux";

// Vars
const depositData = [
  {
    title: "Soul Plan - Cấp độ Expert",
    logo: Images.iconSixPointedStar,
  },

  {
    title: "Matrix Of Destiny - Cấp độ Expert",
    logo: Images.matrix,
  },
];

const TrainingLevels = () => {
  const user_info = getFromLocalStorage(USER_INFO);
  const { user } = useSelector((state) => state.user);

  return (
    <Card>
      <Box className="mt-10">
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            className="border-be md:border-be-0 md:border-ie"
          >
            {user_info ? (
              <Box className="mx-5 my-5 flex items-center gap-4">
                <Avatar
                  alt="Avartar"
                  className="h-[100px] w-[100px]"
                  src={user?.avatar}
                />
                <Box className="">
                  <Typography
                    variant="body1"
                    className="text-xs font-semibold md:text-sm lg:text-base xl:text-lg 2xl:text-xl"
                  >
                    {user?.name}
                  </Typography>
                  <Typography variant="body1" className="text-primary">
                    Email: {user?.email}
                  </Typography>
                  <Typography variant="body1" className="text-primary">
                    Cấp độ: {user?.level?.join(", ")}
                  </Typography>
                  <Typography variant="body1" className="text-primary">
                    Số điện thoại: {user?.phone_number || "---"}
                  </Typography>
                </Box>
              </Box>
            ) : null}
            <Divider />
            <CardContent className="flex flex-col gap-5">
              <Typography variant="body1" className="font-semibold">
                Các cấp độ luyện tập:
              </Typography>
              {depositData.map((item, index) => (
                <div key={index} className="flex items-center gap-4">
                  <img src={item.logo} alt={item.title} width={30} />
                  <div className="is-full flex flex-wrap items-center justify-between gap-x-4 gap-y-2">
                    <div className="flex flex-col gap-0.5">
                      <Typography className="font-medium text-primary">
                        {item.title}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </CardContent>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default TrainingLevels;
