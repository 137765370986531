/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { getFromLocalStorage } from "utils/local-storage";
import { USER_INFO } from "constant/AuthConstant";
import { ADMIN } from "constant/AuthConstant";
// chakra imports

export function SidebarLinks(props) {
  const userInfo = getFromLocalStorage(USER_INFO);
  // Chakra color mode
  let location = useLocation();

  const { routes, routesUser } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    if (userInfo?.user?.role === ADMIN) {
      return routes.map((route, index) => {
        return (
          <Link key={index} to={route.layout + "/" + route.path}>
            <div
              className={`${
                activeRoute(route.path) === true
                  ? "bg-[#e2ecf6] font-bold dark:text-white"
                  : "font-medium text-gray-600"
              } relative flex py-2 hover:cursor-pointer`}
            >
              <li
                className={` my-[3px] flex cursor-pointer items-center px-8`}
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold text-brand-500 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.path) === true
                      ? "font-bold text-primary dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.path) ? (
                <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
              ) : null}
            </div>
          </Link>
        );
      });
    } else {
      return routesUser.map((route, index) => {
        return (
          <Link key={index} to={route.layout + "/" + route.path}>
            <div
              className={`${
                activeRoute(route.path) === true
                  ? "bg-[#e2ecf6] font-bold dark:text-white"
                  : "font-medium text-gray-600"
              } relative flex py-2 hover:cursor-pointer`}
            >
              <li
                className={` my-[3px] flex cursor-pointer items-center px-8`}
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold text-brand-500 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.path) === true
                      ? "font-bold text-primary dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.path) ? (
                <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
              ) : null}
            </div>
          </Link>
        );
      });
    }
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
