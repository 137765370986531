import React from 'react'

const ImageCheck = ({ source, width = "15px", className = "mx-auto" }) => {
  return (
    <img
      src={source}
      className={className}
      alt="tick blue"
      style={{ width: width }}
    />
  );
};

export default ImageCheck