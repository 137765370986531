import * as yup from "yup";
// import { passwordRegExp, regexEmail } from "../helpers";

// export const userSignUpSchema = () => {
//   return yup.object().shape({
//     title: yup.string().nullable().trim(),
//     firstName: yup.string().nullable().trim().required("Bạn phải nhập tên"),
//     lastName: yup.string().nullable().trim().required("Bạn phải nhập họ"),
//     accept_terms: yup.bool().oneOf([true], "Bạn phải đồng ý với điều khoản"),
//     accept_terms_1: yup.bool().oneOf([true], "Bạn phải đồng ý với điều khoản"),
//     phone_number: yup
//       .string()
//       .nullable()
//       .trim()
//       .required("Bạn phải nhập số điện thoại"),
//     email: yup
//       .string()
//       .nullable()
//       .trim()
//       .required("Bạn phải nhập Email")
//       .email("Email không hợp lệ")
//       .matches(regexEmail, "Email không hợp lệ")
//       .max(200, "Email không hợp lệ"),
//     username: yup
//       .string()
//       .nullable()
//       .trim()
//       .required("Bạn phải nhập tên đăng nhập"),
//     password: yup
//       .string()
//       .nullable()
//       .required("Bạn phải nhập Password")
//       .min(8, "Password phải dài hơn 8 kí tự, và bắt đầu bằng chữ IN HOA")
//       .max(64, "Password phải ngắn hơn 64 kí tự")
//       .matches(
//         passwordRegExp,
//         "Password phải dài hơn 8 kí tự, Password phải bắt đầu bằng chữ IN HOA"
//       ),
//     confirm_password: yup
//       .string()
//       .oneOf([yup.ref("password"), null], "Password nhập lại không khớp"),
//   });
// };

// export const LoginSchema = () => {
//   return yup.object().shape({
//     email: yup
//       .string()
//       .nullable()
//       .trim()
//       .required("Bạn phải nhập Email")
//       .email("Email không hợp lệ")
//       .matches(regexEmail, "Email không hợp lệ")
//       .max(200, "Email không hợp lệ"),
//     password: yup.string().nullable().required("Bạn phải nhập Mật khẩu"),
//     // .min(8, "Mật khẩu phải dài hơn 8 kí tự")
//     // .max(64, "Mật khẩu phải ngắn hơn 64 kí tự"),
//   });
// };

// export const ForgotPassSchema = () => {
//   return yup.object().shape({
//     email: yup
//       .string()
//       .nullable()
//       .trim()
//       .required("Bạn phải nhập Email")
//       .email("Email không hợp lệ")
//       .matches(regexEmail, "Email không hợp lệ")
//       .max(200, "Email không hợp lệ"),
//   });
// };

// export const ChangePasswordSchema = () => {
//   return yup.object().shape({
//     oldPassword: yup
//       .string()
//       .nullable()
//       .required("Bạn phải nhập Password")
//       .min(8, "Password phải dài hơn 8 kí tự")
//       .max(64, "Password phải ngắn hơn 64 kí tự")
//       .matches(passwordRegExp, "Password phải bắt đầu bằng chữ IN HOA"),
//     newPassword: yup
//       .string()
//       .nullable()
//       .required("Bạn phải nhập Password")
//       .min(8, "Password phải dài hơn 8 kí tự")
//       .max(64, "Password phải ngắn hơn 64 kí tự")
//       .matches(passwordRegExp, "Password phải bắt đầu bằng chữ IN HOA"),
//     retypePassword: yup
//       .string()
//       .oneOf([yup.ref("newPassword"), null], "Password nhập lại không khớp"),
//   });
// };


export const userFormSchema = () => {
  return yup.object().shape({
    name: yup
      .string()
      .nullable()
      .trim()
      .required("Vui lòng nhập họ tên"),
    email: yup
      .string()
      .nullable()
      .trim()
      .required("Vui lòng nhập email"),
    phone_number: yup
      .string()
      .nullable()
      .trim()
      .required("Vui lòng nhập số điện thoại"),
    // level: yup
    //   .array()
    //   .of(yup.string())
    //   .required("Vui lòng chọn level"),
  });
};