import { fetchClient } from "api/app.service";
import { UPLOAD_URL } from "constant/Api";
import { FILE } from "constant/Api";

export const uploadSingleFile = async (
  hasToken,
  formFile,
  fileName = "image",
  folderName = "images"
) => {
  var formData = new FormData();
  formData.append(fileName, formFile);
  formData.append("fileName", fileName);
  formData.append("folderName", folderName);

  return fetchClient(hasToken, true)
    .post(`${UPLOAD_URL}${FILE}`, formData)
    .then((res) => {
      if (res.status === 200) {
        return res.data.data.storage;
      }
    })
    .catch((error) => {
      throw error;
    });
};
