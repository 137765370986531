import React from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS cho DatePicker
import "../index.css";

// Các imports khác
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { formattedDateSoulPlan } from "utils/helpers";
import { createMatrix } from "services/matrix.service";
import { setLocalStorage } from "utils/local-storage";
import { PDF_DATA } from "constant/AuthConstant";
import { toast } from "react-toastify";

const CreateForm = ({ open, handleClose, setExportData }) => {
  const { handleSubmit, control, reset } = useForm();

  const onSubmit = async (data) => {
    data.birthday = formattedDateSoulPlan(data.birthday);
    try {
      const response = await createMatrix(data);
      console.log("response: ", response);
      if (response.status === 200) {
        setExportData((prev) => {
          console.log("prev: ", prev);
          prev?.data.unshift(response?.data?.data);
          return prev;
        });
        setLocalStorage(PDF_DATA, response?.data);
        window.open("/blank/view", "_blank");
        handleClose();
        reset();
      }
    } catch (error) {
      toast.error("Đã có lỗi xảy ra");
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h4" className="text-center">
            Soul Plan Personal
          </Typography>
          <Typography variant="body1" className="text-center">
            Nhập họ tên và ngày tháng năm sinh, để xuất báo cáo Soul Plan
            Personal.
          </Typography>
          <Box className="mt-6">
            <form onSubmit={handleSubmit(onSubmit)} className="w-full px-0">
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Box className="mt-3">
                    <Controller
                      name="fullName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Họ và tên"
                          placeholder="Nguyễn Văn A"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className="ri-user-3-line" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="birthday"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        selected={field.value ? new Date(field.value) : null}
                        onChange={(date) => field.onChange(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Chọn ngày sinh"
                        className="h-10 w-2/5 rounded-md border border-gray-300 px-3"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">Giới tính:</Typography>
                  <Controller
                    name="gender"
                    control={control}
                    className="w-full"
                    defaultValue="male"
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        className="flex w-full flex-row gap-3"
                      >
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Nam"
                          className="w-auto"
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Nữ"
                          className="w-auto"
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label="Khác"
                          className="w-auto"
                        />
                      </RadioGroup>
                    )}
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  className="border border-primaryPurple text-primaryPurple"
                >
                  Hủy bỏ
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="bg-primaryPurple"
                >
                  Xuất báo cáo
                </Button>
              </DialogActions>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateForm;
